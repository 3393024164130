<template>
  <v-app class="pattern-bg" v-if="!loading">
    <v-img
      class="logo-xbussiness"
      src="../assets/logo_x_auxiliar_7.png"
    ></v-img>
    <v-navigation-drawer
      v-if="menu != 'none'"
      v-model="drawer"
      v-resize="onResize"
      :mini-variant.sync="mini"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      class="elevation-2"
      app
      clipped
    >
      <MenuDashBoard v-if="menu === 'dashboard'" />
    </v-navigation-drawer>

    <v-app-bar
      class="elevation-1 v-app-bar-gradient"
      app
      clipped-left
      clipped-right
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        @click:outside="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div class="d-xs-none d-sm-flex ml-3" style="max-width: 180px">
        <v-img contain height="60px" src="@/assets/logo cabeçalho.png"></v-img>
      </div>
      <v-spacer></v-spacer>

      <v-btn
        v-if="role == 'ADMIN'"
        icon
        @click.stop="dialogVersionApp = !dialogVersionApp"
      >
        <v-icon> mdi-cellphone-information </v-icon>
      </v-btn>

      <v-divider
        v-if="role == 'ADMIN'"
        vertical
        class="mr-6 ml-3"
        dark
      ></v-divider>

      <v-btn
        v-if="role == 'ADMIN'"
        color="xbColor"
        depressed
        @click.stop="drawerPosts = !drawerPosts"
      >
        <v-icon left> mdi-square-edit-outline </v-icon>
        Posts
      </v-btn>

      <v-divider
        v-if="role == 'ADMIN'"
        vertical
        class="ml-6 mr-2"
        dark
      ></v-divider>

      <v-btn dark icon @click.prevent="deslogarUsuario">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- menu lateral direto -->
    <DrawerPosts v-if="drawerPosts" :drawerPosts.sync="drawerPosts" />
    <DialogVersion
      v-if="dialogVersionApp"
      :dialogVersionApp.sync="dialogVersionApp"
    />

    <v-main>
      <transition mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <!-- <Profile :dialogProfile.sync="dialogProfile" /> -->
  </v-app>
  <v-app v-else>
    <v-main>
      <v-container fluid class="fill-height align-center justify-center">
        <v-progress-circular
          :width="5"
          indeterminate
          :color="dark ? 'white' : 'primary'"
          size="80"
        ></v-progress-circular>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";

export default {
  name: "Layout",
  components: {
    DrawerPosts: () => import("./components/DrawerPosts.vue"),
    DialogVersion: () => import("@/layouts/components/DialogVersion.vue"),
    MenuDashBoard: () => import("./menus/MenuDashBoard.vue"),
  },
  data() {
    return {
      drawer: true,
      mini: true,
      drawerPosts: false,
      dialogVersionApp: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      apiUrl: null,
      menu: "",
      loading: false,
    };
  },

  computed: {
    year() {
      return format(new Date(), "yyyy");
    },
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      usuario_name: (state) => state.usuario.name,
      avatar: (state) => state.usuario.avatar,
      role: (state) => state.role,
    }),
    nome() {
      let splitName = this.usuario_name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      firstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
      lastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      let name = firstName + " " + lastName;
      return name;
    },
  },

  watch: {
    $route() {
      if (this.$route.meta.menu) {
        this.menu = this.$route.meta.menu;
      }
    },
  },

  methods: {
    deslogarUsuario() {
      this.loading = true;
      this.$store.dispatch("Usuario/deslogarUsuario").then(() => {
        this.$router.push("/login");
        this.loading = false;
      });
    },
    // setTemplate() {
    //   if (this.light) {
    //     this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
    //     this.$ls.set("template", "dark");
    //     this.$vuetify.theme.dark = true;
    //   } else {
    //     this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
    //     this.$ls.set("template", "light");
    //     this.$vuetify.theme.dark = false;
    //   }
    // },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      if (this.windowSize.x < 1264) {
        return (this.mini = false);
      }
    },
  },

  mounted() {
    if (this.$route.meta.menu) {
      this.menu = this.$route.meta.menu;
    } else {
      this.menu = "dashboard";
    }
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }

    this.onResize();
  },
};
</script>

<style lang="scss" scoped>
.pattern-bg {
  background-color: f5f5f5 !important;

  width: 100vw;
  height: 100%;
}

.v-app-bar-gradient {
  background: rgb(5, 5, 5);
  background: linear-gradient(
    90deg,
    rgba(5, 5, 5, 1) 0%,
    rgba(37, 37, 37, 1) 49%
  );
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
}

.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
.logo-xbussiness {
  position: absolute;
  width: 100px;
  bottom: 25px;
  right: 25px;
}
</style>
